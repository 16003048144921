// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-common-problems-js": () => import("./../../../src/pages/common-problems.js" /* webpackChunkName: "component---src-pages-common-problems-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-counseling-faq-js": () => import("./../../../src/pages/counseling-faq.js" /* webpackChunkName: "component---src-pages-counseling-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-approach-js": () => import("./../../../src/pages/my-approach.js" /* webpackChunkName: "component---src-pages-my-approach-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-services-page-layout-js": () => import("./../../../src/templates/services-page-layout.js" /* webpackChunkName: "component---src-templates-services-page-layout-js" */)
}

